.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 0.5rem;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #2196F3;
}

input:checked + .toggle-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #fbbf24;
}

.fa-sun, .fa-moon {
  font-size: 1.5rem;
}
.light {
  --bg-color: #ffffff;
  --text-color: #111827;
  --accent-color: #6366f1;
}

.dark {
  --bg-color: #111827;
  --text-color: #ffffff;
  --accent-color: #fbbf24;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.navbar {
  background-color: var(--bg-color);
  color: var(--text-color);
  /* Other styles for the Navbar... */
}